import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/(home)/search/HeaderSearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/(RouteBlockers)/AuthorizationWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/(RouteBlockers)/FeatureFlagStoreInitaitor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageDropDown"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/components/Language/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/components/NavBar/LogoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/components/NavBar/NavItemsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20250325154134/app/[lng]/components/NavBar/UserProfile.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250325154134/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250325154134/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20250325154134/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
